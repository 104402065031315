@import "src/styles/variables";
@import "src/styles/mixins";

.products-carousel-card {
  display: inline-block;
  background-color: transparent;
  width: 290px;
  height: 450px;
  perspective: 1000px;
  transition: all 0.2s;

  .card-inner {
    position: relative;
    text-align: center;
    transition: transform 0.2s;
    transform-style: preserve-3d;

    h3,
    p {
      letter-spacing: normal;
    }

    .card-front {
      width: 290px;
      height: 450px;
      background: $lightest-grey;
      box-shadow: 1px 15px 22px 0px $black-translucent;
      border-radius: 20px;

      .image-container {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0.93px 1.86px 0px #0000001f;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        img {
          display: block;
          width: 100%;
          height: 332px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          object-fit: contain;
        }
      }

      h3 {
        margin-top: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
      }

      p {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .card-back {
      width: 370px;
      height: 542px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 62px 47px;
      border: 1px solid $dark-grey;
      border-radius: 24px;
      box-shadow: 1px 18px 26px 0px $black-translucent;
      background: $white;

      .close-button {
        position: absolute;
        top: 20px;
        right: 14px;
      }

      img {
        height: 95px;
      }

      h3 {
        margin: 30px 0 14px;
        font-size: 38px;
        line-height: 41px;
        text-align: left;
      }

      p {
        width: 257px;
        font-size: 20px;
        text-align: left;
        line-height: 28px;
      }

      .cta-button {
        margin-top: auto;
        width: 180px;
        height: 50px;

        p {
          width: auto;
          font-size: 18px;
          text-align: center;
          line-height: 0;
        }
      }

      .cta-no-link {
        cursor: initial;
        background-color: #fff;
        border: transparent;
        outline: transparent;
        display: flex;
        justify-content: flex-start;
        
        p {
          margin-top: 15px;
          color: #181818;
          font-size: 20px;  
        }
      }
    }

    .card-front,
    .card-back {
      position: absolute;
      backface-visibility: hidden;
      color: $dark-grey;
    }
  }

  &.overlay {
    transform: translate(-40px, -20%);
  }

  &:not(.overlay) .card-back {
    transform: rotateY(180deg);
  }

  @include for-vertical-tablet-up {
    &:hover {
      z-index: 1;

      .card-inner {
        transform: translate(40px, -30px) rotateY(180deg);
      }
    }
  }
}

@include for-large-desktop-up {
  .products-carousel-card {
    width: 400px;

    .card-inner {
      .card-front {
        width: 400px;
      }
      .card-back {
        width: 480px;
      }
    }
  }
}
