@import "src/styles/variables";
@import "src/styles/mixins";

@mixin default-button-config {
  font-size: 16px;
  width: 167px;
  height: 50px;
  font-family: $font-regular;
}

.content-area {
  .consumer-content {
    strong {
      font-family: $font-semibold;
    }
    .raise-earn {
      &:visited {
        color: inherit;
      }
    }
    h1 {
      font-size: 40px;
      line-height: 40px;
    }
  }
  .download-app-button {
    background-color: #fff;
    color: #181818;
    @include default-button-config;
    &:hover {
      background-color: $green;
    }
  }
  .business-content {
    &.hero-scene-description {
      max-width: none;
      padding-top: 33px;
      gap: 20px;
    }
    h1 {
      color: $white;
      font-size: 28px;
      line-height: 33px;
    }
    .business-description {
      color: $white;
      padding: 0 10px;
    }
    .learn-more-button {
      @include default-button-config;
      color: $white;
      border-color: $white;
      &:hover {
        border-color: $purple;
      }
    }
  }
}

@include for-vertical-tablet-up {
  .content-area {
    .consumer-content {
      padding-top: 21px;
      max-width: 330px;
      h1 {
        font-size: 50px;
        line-height: 1;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .business-content {
      &.hero-scene-description {
        padding-top: 23px;
      }

      h1 {
        font-size: 38px;
        line-height: 45.6px;
      }

      .business-description {
        font-size: 20px;
        padding: 0;
        line-height: 28px;
      }
    }
  }
}

@include for-desktop-up {
  .content-area {
    .consumer-content {
      justify-content: flex-start;
      margin: 0;
      align-items: flex-start;
      max-width: 500px;
      text-align: left;
      h1 {
        font-size: 78px;
        line-height: 78px;
      }
      .scene-description,
      .download-app-button {
        display: none;
      }
    }

    .business-content {
      &.hero-scene-description {
        gap: 0;
        max-width: 834px;
      }
      margin: 0;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      h1 {
        font-size: 62px;
        line-height: 74px;
      }
      .business-description {
        padding: 0;
        max-width: 568px;
        margin-top: 33px;
        margin-bottom: 40px;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
